import React from "react";

import ShoppingCartController, {
  Props,
  configJSON,
} from "./ShoppingCartController";

// Customizable Area Start
import { arrowLeft, NoProduct } from "./assets";
import "../assets/css/emptyCart.css";
import CartSkeltonLoader from "../../studio-store-restaurant-components/src/SkeletonLoader/CartSkeltonLoader";
// Customizable Area Start

export class EmptyCart extends React.Component {
  constructor(props: Props) {
    super(props);
    this.state = {
      isShowingSkeltonLoader: true,
    };
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  componentDidMount() {
    setTimeout(() => {
      this.setState({ isShowingSkeltonLoader: false });
    }, 2000);
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <div>
        <div className="p-3 yt-empty-cart bg-white radius-10 mb-4 m-auto">
          <div className="profile-pg-order-main-wrap text-center ">
            {/* @ts-ignore */}
            {this.state.isShowingSkeltonLoader ? (
              <CartSkeltonLoader />
            ) : (
              <>
                <div className="img-empty-basket  mb-5">
                  <img src={NoProduct} className="img-fluid" />
                </div>
                <div className="pp-sa-order-wrap mb-5 mt-2">
                  <h2 className="empty-cart-text mt-0 mb-3">Empty basket</h2>
                  <p className="pp-od-no-text mb-0">
                    <img
                      src={arrowLeft}
                      alt="arrowLeft"
                      height={19}
                      width={15}
                      className="img-fluid "
                    />{" "}
                    <span className="p-1">
                      Start adding items to your order
                    </span>
                  </p>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    );
    // Customizable Area End
  }
}

export default EmptyCart;
// Customizable Area Start
// Customizable Area End
